import * as React from "react";
import { ReactNode } from "react";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { Link } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';

export default class Events extends React.Component<any, any> {
  render(): ReactNode {
    const posts = this.props.data.eventList.edges;
    const pageInfo = this.props.data.eventList.pageInfo;
    const intl = this.props.pageContext.intl;
    const messages = this.props.pageContext.intl.messages;

    const Cards = (props) => {
      return <>{
        _.orderBy(props.data, ['node.frontmatter.date', ['node.frontmatter.title']], ['desc', 'asc'])
          .map(edge => {
            const {frontmatter} = edge.node;
            const {cover, locale} = frontmatter;
            const classDirection = (locale === 'ps' || locale === 'fa') ? 'text-rtl' : 'text-ltr';

            return (
              <a href={frontmatter.slug} className={"ui link card overlay-caption " + classDirection} key={edge.node.id}>
                <div className="image">
                  <div className="image">
                    {cover && <GatsbyImage
                      image={cover?.childImageSharp?.gatsbyImageData}
                      alt={frontmatter.title}
                      className="ui centered big image"/>}
                  </div>
                </div>

                <div className="content">
                  <div className="header">{frontmatter.title}</div>
                  <div className="meta">
                    <div className="date text-ltr">{frontmatter.date}</div>
                  </div>
                </div>
              </a>
            );
          })
      }</>;
    };

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{messages.aps_events}</title>
        </Helmet>

        <div className="center aligned row">
          <div className="column">
            <div className="ui four cards" style={{alignItems: 'baseline'}}>
              <Cards data={posts}/>
            </div>
          </div>
        </div>

        <div className="center aligned row">
          <div className="column">
            {pageInfo.hasPreviousPage &&
            <Link to={pageInfo.currentPage === 2 ? `/events` : `/events/${pageInfo.currentPage - 1}`}>
              <button className={"ui right labeled primary icon button " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
                <i className="icon arrow left"></i>
                {messages.previous_page}
              </button>
            </Link>
            }
            {pageInfo.hasNextPage &&
            <Link to={`/events/${pageInfo.currentPage + 1}`}>
              <button className={"ui right labeled primary icon button " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
                <i className="icon arrow right"></i>
                {messages.next_page}
              </button>
            </Link>
            }
          </div>
        </div>

      </Layout>
    );
  }
}

export const eventsQuery = graphql`query eventsQuery($skip: Int!, $limit: Int!) {
  eventList: allMarkdownRemark(
    filter: {frontmatter: {slug: {regex: "/^\\/events.*/"}}}
    limit: $limit
    skip: $skip
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    pageInfo {
      itemCount
      pageCount
      perPage
      hasPreviousPage
      hasNextPage
      currentPage
      totalCount
    }
    edges {
      node {
        id
        frontmatter {
          title
          slug
          date
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 266
                height: 230
                transformOptions: {cropFocus: CENTER, fit: COVER}
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
}
`;