import * as React from 'react';
import Header from './header';
import Footer from './footer';
import { injectIntl } from "gatsby-plugin-react-intl";
import "../styles/global-styles.css";

class Layout extends React.Component<any> {
  render() {
    return (
      <>
        <Header/>
        <div id="top-container" className="ui big container grid" style={{marginTop: '0px', marginBottom: '20px'}}>
          {this.props.children}
        </div>
        <Footer/>
      </>
    );
  }
}

export default injectIntl(Layout);